/*
 **  This file has been migrated to the new translated structure.
 **  1) All strings must be added with the correct casing.
 **  2) New strings must be added to the the en_US_migrated.json.
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import { get_permissable_routes } from "../../routing/appRouter";
import { siteConfig } from "../../settings/config";
import { FormattedMessage } from "react-intl";
import SidebarWrapper from "./sidemenu.style";

import { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import Scrollbar from "react-smooth-scrollbar";

import AppContext from "../../AppContext";

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

class Sidebar extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {};
  }

  getSelectedKeys = paths => {
    let selected = [];

    paths.forEach(r => {
      if (r.children) {
        r.children.forEach(c => {
          let path = `/${c.product}/${c.regex ? c.regex : c.path}`;

          if (path === this.props.match.path) {
            selected.push(c.key);
          }
        });
      } else {
        let path = `/${r.product}/${r.regex ? r.regex : r.path}`;

        if (path === this.props.match.path) {
          selected.push(r.key);
        }
      }
    });

    return selected;
  };

  getOpenedKeys = paths => {
    let opened = [];

    paths.forEach(r => {
      if (r.children) {
        r.children.forEach(c => {
          let path = `/${c.product}/${c.regex ? c.regex : c.path}`;

          if (path === this.props.match.path) {
            opened.push(r.key);
          }
        });
      }
    });

    return opened;
  };

  getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
    const { key, path, label, leftIcon, product, onMenu, children } = singleOption;
    if (onMenu) {
      let url = "/" + product;
      if (children) {
        return (
          <SubMenu
            key={key}
            icon={leftIcon !== undefined ? leftIcon : false}
            title={
              <span className="sidebarMenuHolder" style={submenuColor}>
                {!this.context.sbCollapsed || leftIcon === undefined ? (
                  <span className="navText">
                    <FormattedMessage id={label} />
                  </span>
                ) : (
                  false
                )}
              </span>
            }
          >
            {children.map(child => {
              if (child.onMenu) {
                let curl = child.product;
                const linkTo = child.withoutDashboard ? `/${child.path}` : `/${curl}/${child.path}`;

                return (
                  <Menu.Item style={submenuStyle} key={child.key}>
                    <Link style={submenuColor} to={linkTo}>
                      <span className="navText subMenu">
                        <FormattedMessage id={child.label} />
                      </span>
                    </Link>
                  </Menu.Item>
                );
              } else {
                return false;
              }
            })}
          </SubMenu>
        );
      }
      return (
        <Menu.Item key={key}>
          <Link to={`${url}/${path}`}>
            <span className="sidebarMenuHolder" style={submenuColor}>
              {leftIcon !== undefined ? leftIcon : false}
              &nbsp;
              {!this.context.sbCollapsed || leftIcon === undefined ? (
                <span className="navText">
                  <FormattedMessage id={label} />
                </span>
              ) : (
                false
              )}
            </span>
          </Link>
        </Menu.Item>
      );
    } else {
      return false;
    }
  };
  render() {
    const options = get_permissable_routes(
      this.context.permissions[this.context.active_role],
      this.context.roles[this.context.active_role]
    );
    const selected = this.getSelectedKeys(options);
    const opened = this.getOpenedKeys(options);
    var lang = this.context.currentLanguage();

    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible
          collapsed={this.context.sbCollapsed}
          theme={this.props.theme.sidebarTheme}
          className="sidebarMenuContainer"
        >
          <div
            className={this.context.sbCollapsed ? "sidebarMenuLogoCollapsed" : "sidebarMenuLogo"}
            onClick={() => {
              this.context.trackLyticsEvent("Interaction", "Logo Clicked");
              this.context.trackLyticsPage("Home");
              this.context.navigate("/");
            }}
          >
            {this.context.sbCollapsed ? (
              <img src={siteConfig[this.props.theme.sidebarIcon]} alt="" />
            ) : (
              <img src={siteConfig[this.props.theme.sidebarLogo][lang]} alt="" />
            )}
          </div>
          <Scrollbar className="sidebarMenuContentScroll">
            <Menu
              onClick={this.handleClick}
              theme={this.props.theme.sidebarTheme}
              className="sidebarMenuContent"
              mode={this.context.sbCollapsed ? "vertical" : "inline"}
              defaultOpenKeys={this.context.sbCollapsed ? [] : opened}
              selectedKeys={selected}
            >
              {options.map(singleOption => this.getMenuItem({ singleOption: singleOption }))}
            </Menu>
          </Scrollbar>
          <div className="sidebarMenuFooter">
            {this.props.footer !== undefined && !this.context.sbCollapsed ? this.props.footer : false}
          </div>
        </Sider>
      </SidebarWrapper>
    );
  }
}

export default withRouter(withTheme(Sidebar));
