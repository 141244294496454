const config = {
  apiGateway: {
    URL: "https://6lclk0a9gc.execute-api.ca-central-1.amazonaws.com/v1/",
    AUTH: "https://fu0c0qzy38.execute-api.ca-central-1.amazonaws.com/v1/",
    REGION: "ca-central-1"
  },
  sso_enabled: true,
  sso_config: {
            auth_url: "ipc-prod.auth.ca-central-1.amazoncognito.com",
            identity_provider: "IPC-PROD-AD",
            scope: "openid+aws.cognito.signin.user.admin",
            grant_type: "authorization_code",
            client_id: "23qf03u7982sd3n60jets21cqj",
            redirect_uri: "https://foureyes.ipcc.ca/authorize",
        },
  canadaPostApiKey: "FJ54-EN35-GP28-MZ11"
}

var siteConfig = {
  title: "IPC",
  fourlyticsId: false,
  fourlyticsScreenRecording: false
};

var componentConfig = {
  
};

export {config, siteConfig, componentConfig}
