import { createGlobalStyle } from "styled-components";
const GlobalTheme = createGlobalStyle`
  body {
    font-size: ${props => props.theme.text.default};
    font-family: ${props => props.theme.font};
    color: ${props => props.theme.color["Primary Text"]};
  }

  .fa-ease-in-out-spin{
    -webkit-animation: fa-spin 2s infinite ease-in-out;
    animation: fa-spin 2s infinite ease-in-out;
  }

  .ql-editor {
    overflow: visible;
  }

  .text-capitalize {
    text-transform: capitalize;
  }

  .text-capitalize-first::first-letter {
    text-transform: capitalize;
  }

  .textUpperCase {
    text-transform: uppercase;
  }

  .transparentBackground {
    background: transparent !important;
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent !important;
    border-right: 3px solid #fff;
  }
  
  .ant-btn.disabled, .ant-btn[disabled] {
    cursor: default !important;
  }
  .ant-dropdown-menu-item-disabled, .ant-dropdown-menu-submenu-title-disabled, .ant-dropdown-menu-item-disabled:hover, .ant-dropdown-menu-submenu-title-disabled:hover {
    cursor: default !important;
  }

  ant-checkbox-disabled + span, .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
    cursor: default !important;
  }
  
  .ant-drawer-title {
    font-size: inherit !important;
  }

  .span2c {
    grid-column: span 2;
  }

  @media print {
    @page {
      margin: .5in;
    }
  
    .ant-layout-footer,
    .ant-layout {
      background-color: transparent !important;
    }
  
    body {
      font-size: 8pt !important;
    }
  
    .ant-layout {
      height: auto !important;
    }
  
    .isomorphicSidebar {
      flex: 0 0 0;
      max-width: 0;
      min-width: 0;
      width: 0;
      display: none !important;
    }
  
    .isomorphicTopbar,
    .ant-back-top {
      display: none !important;
    }
  
    .isoContentMainLayout {
      position: relative !important;
      width: auto !important;
      height: auto !important;
      overflow: visible !important;
      transform: scale(1);
      transform-origin: top left;
    }

    .ant-back-top {
      right: 40px !important;
    }

    .fefDashboard {
      padding: 0;
    }
  }

  .center-text {
    text-align: center;
  }

  .noLeft {
    padding-left: 0px;
  }

  .displayGrid {
    display: grid;
  }

  .fullWidth {
    width: 100%;
  }

  .tooltipMaxWidth {
    max-width: 100%;
  }

  .grid2Rows {
    grid-template-rows: 50% 50%;
  }

  .grid3Rows {
    grid-template-rows: 33% 33% auto;
  }

  .grid5Col{
    grid-template-columns: repeat(5, 1fr)
  }

  .grid4Col{
    grid-template-columns: repeat(4, 1fr);
  }

  .grid3Col{
    grid-template-columns: repeat(3, 1fr)
  }

  .grid2Col{
    grid-template-columns:repeat(2, 1fr)
  }

  .gridColGap1em{
    grid-column-gap:1em;
  }

  .orangeText {
    color: ${({ theme }) => theme.color.orange["orange-4"]};
  }

  .drawerTitle {
    font-weight: ${({ theme }) => theme.text.bold};
    font-size: ${({ theme }) => theme.text.f18};
    color: ${({ theme }) => theme.color["Primary text"]};
  }

  .textSemibold {
    font-weight: ${({ theme }) => theme.text.semibold};
  }

  .textBold {
    font-weight: ${props => props.theme.text.bold} !important;
  }

  .textLight {
    font-weight:${props => props.theme.text.light} !important;
  }

  .text-color-error {
    color: ${props => props.theme.color["Error Primary"]};
  }

  .textColorWarning {
    color: ${props => props.theme.color["Warning Primary"]};
  }
  
  .textColorSuccess {
    color: ${props => props.theme.color["Success Primary"]};
  }
  
  .textColorPrimary {
    color: ${props => props.theme.color["Primary text"]};
  }

  .textColorSecondary {
    color: ${props => props.theme.color["Secondary text"]};
  }

  .textMagenta {
    color:${props => props.theme.color.magenta["magenta-5"]};
  }

  .textGreen{
    color: ${props => props.theme.color.green["green-5"]};
  }

  .noArrow {
    .ant-popover-content {
      .ant-popover-arrow {
        display: none !important;
      }
    }
  }

  .ellipsis {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .justifyEnd {
    justify-self: end;
  }

  .alignSelfCenter { 
    align-self: center; 
  }
  
  .alignSelfEnd {
    align-self: end;
  }

  .left {
    text-align:left;
  }
  
  .right {
    text-align:right;
  }

  .marginRight {
    margin-right: .5em;
  }

  .marginBottom {
    margin-bottom: 1em;
  }

  .marginTop{
    margin-top:1em;
  }

  .marginSmallBottom{
    margin-bottom:0.5em;
  }

  .marginSmallTop{
    margin-top:0.5em;
  }

  .marginSmallBottomMinus{
    margin-bottom:-0.5em;
  }

  .marginSmallTopMinus{
    margin-top:-0.5em;
  }

  .borderBottom{
    border-bottom: 1px solid ${props => props.theme.color["Border"]};
  }

  .borderTop {
    border-top: 1px solid ${props => props.theme.color.grey["grey-10"]};
  }

  .percent{
    font-size:${props => props.theme.text.f12};
  }

  .fefButton{
    border-radius: 4px;
  }

  .grey9{
    color: ${props => props.theme.color.grey["grey-9"]}
  }

  .radioSpacing {
    .ant-radio-wrapper {
        margin-right: 1.5em;
    }
  }

  .activeStatusColor {
    color: ${props => props.theme.color.green["green-6"]};
    &:hover, &:active, &:focus {
      color: ${props => props.theme.color.green["green-5"]};
    }
  }

  .inactiveStatusColor {
    color: ${props => props.theme.color.grey["grey-6"]};
  }

  .pendingStatusColor {
    color: ${props => props.theme.color.orange["orange-6"]};
  }

  .disabledStatusColor {
    color: ${props => props.theme.color.red["red-6"]};
    &:hover, &:active, &:focus {
      color: ${props => props.theme.color.red["red-5"]};
    }
  }

  .textRed{
    color:${props => props.theme.color.red["red-5"]} !important;
  }

  .headerLarge {
    color: ${props => props.theme.color.grey["grey-10"]};
    font-size: ${props => props.theme.text.f30};
    font-weight: ${props => props.theme.text.regular};
    text-transform: capitalize;
  }

  .header1 {
    color: ${props => props.theme.color.grey["grey-10"]};
    font-size: ${props => props.theme.text.f20};
    font-weight: ${props => props.theme.text.regular};
    text-transform: capitalize;
  }
  
  .header1Lower {
    color: ${props => props.theme.color.grey["grey-10"]};
    font-size: ${props => props.theme.text.f20};
    font-weight: ${props => props.theme.text.regular};
  }

  .header1Light{
    color: ${props => props.theme.color.grey["grey-7"]};
    font-size: ${props => props.theme.text.f20};
    font-weight: ${props => props.theme.text.light};
    text-transform: capitalize;
  }
  
  .header1LowerLight{
    color: ${props => props.theme.color.grey["grey-7"]};
    font-size: ${props => props.theme.text.f20};
    font-weight: ${props => props.theme.text.light};
  }

  .header2 {              
    color: ${props => props.theme.color["Primary text"]};
    font-size: ${props => props.theme.text.f18};
    font-weight: ${props => props.theme.text.bold};
    text-transform: capitalize;
  }

  .header2Light {
    color: ${props => props.theme.color.grey["grey-7"]};
    font-size: ${props => props.theme.text.f18};
    font-weight: ${props => props.theme.text.bold};
    text-transform: capitalize;
  }

  .header2Lower {              
    color: ${props => props.theme.color["Primary text"]};
    font-size: ${props => props.theme.text.f18};
    font-weight: ${props => props.theme.text.bold};
  }

  .header3 {
    color: ${props => props.theme.color["Primary text"]}; 
    font-size: ${props => props.theme.text.f16};
    font-weight: ${props => props.theme.text.bold}; 
    text-transform: capitalize;
  }
  
  .header3Lower {
    color: ${props => props.theme.color["Primary text"]}; 
    font-size: ${props => props.theme.text.f16};
    font-weight: ${props => props.theme.text.bold}; 
  }

  .header4 {
    color: ${props => props.theme.color["Secondary text"]};
    font-size: ${props => props.theme.text.f16};
    font-weight: ${props => props.theme.text.bold};
    text-transform: capitalize;
  }
  
  .header4Lower {
    color: ${props => props.theme.color["Secondary text"]};
    font-size: ${props => props.theme.text.f16};
    font-weight: ${props => props.theme.text.bold};
  }

  .header5 {
    color: ${props => props.theme.color["Secondary text"]};
    font-size: ${props => props.theme.text.f14};
    font-weight: ${props => props.theme.text.bold};
    text-transform: capitalize;
  }
  
  .header5Lower {
    color: ${props => props.theme.color["Secondary text"]};
    font-size: ${props => props.theme.text.f14};
    font-weight: ${props => props.theme.text.bold};
  }

  .header6 {
    color: ${props => props.theme.color["Primary text"]};
    font-size: ${props => props.theme.text.f14};
    font-weight: ${props => props.theme.text.bold};
    text-transform: capitalize;
  }

  .header6Lower {
    color: ${props => props.theme.color["Primary text"]};
    font-size: ${props => props.theme.text.f14};
    font-weight: ${props => props.theme.text.bold};
  }

  .fieldLabel {
    color: ${props => props.theme.color["Primary text"]};
    font-size: ${props => props.theme.text.f14};
    font-weight: ${props => props.theme.text.regular};
  }
  
  .fieldLabelSmall {
    color: ${props => props.theme.color["Primary text"]};
    font-size: ${props => props.theme.text.f12};
    font-weight: ${props => props.theme.text.regular};
  }

  .fieldValueImp {
    color: ${props => props.theme.color["Title"]};
    font-size: ${props => props.theme.text.f16};
    font-weight: ${props => props.theme.text.bold};
    text-transform: capitalize;
  }

  .fieldValue {
    color: ${props => props.theme.color["Primary text"]};
    font-size: ${props => props.theme.text.f14};
    font-weight: ${props => props.theme.text.semibold};
  }

  .fieldValueSmall {
    color: ${props => props.theme.color["Primary text"]};
    font-size: ${props => props.theme.text.f12};
    font-weight: ${props => props.theme.text.semibold};
  }

  .fieldValueSmallLight {
    color: ${props => props.theme.color.grey["grey-7"]};
    font-size: ${props => props.theme.text.f12};
    font-weight: ${props => props.theme.text.semibold};
  }

  .fieldValueSub {
    color: ${props => props.theme.color["Secondary text"]};
    font-size: ${props => props.theme.text.f12};
    font-weight: ${props => props.theme.text.regular};
  }

  .fieldValueLight {
    color: ${props => props.theme.color["Secondary text"]};
    font-size: ${props => props.theme.text.f14};
    font-weight: ${props => props.theme.text.regular};
  }

  .tableHeader{
    background-color: ${props => props.theme.color["Table header"]}
  }

  .menuLabel {
    color: ${props => props.theme.color["Title"]};
    font-size: ${props => props.theme.text.f14};
    font-weight: ${props => props.theme.text.bold};
    text-transform: capitalize;
  }

  .linkColor {
    color: ${props => props.theme.color.blue["blue-6"]};
  }

  .menuLabelSub {
    color: ${props => props.theme.color["Secondary text"]};
    font-size: ${props => props.theme.text.f12};
    font-weight: ${props => props.theme.text.regular};
  }

  .tableTextImp {
    color: ${props => props.theme.color["Title"]};
    font-size: ${props => props.theme.text.f14};
    font-weight: ${props => props.theme.text.bold};
  }

  .tableTextSemiBold {
    color: ${props => props.theme.color["Title"]};
    font-size: ${props => props.theme.text.f14};
    font-weight: ${props => props.theme.text.semibold};  
  }

  .tableText {
    color: ${props => props.theme.color["Primary text"]};
    font-size: ${props => props.theme.text.f14};
    font-weight: ${props => props.theme.text.regular};
  }

  .tableTextSub {
    color: ${props => props.theme.color["Secondary text"]};
    font-size: ${props => props.theme.text.f12};
    font-weight: ${props => props.theme.text.regular};
  }

  .descriptionText {
    color: ${props => props.theme.color["Primary text"]};
    font-size: ${props => props.theme.text.f14};
    font-weight: ${props => props.theme.text.regular};
  }   

  .descriptionTextLarge{
    color: ${props => props.theme.color["Primary text"]};
    font-size: ${props => props.theme.text.f16};
    font-weight: ${props => props.theme.text.regular};
  }

  .formsScreenTitle {
    color: ${({ theme }) => theme.color.grey["Primary text"]};
    font-size: ${props => props.theme.text.f18};
    font-weight: ${props => props.theme.text.bold};
    margin-bottom: ${props => props.theme.emConverter(12)};
  }

  .formsScreenSubtitle {
    color: ${props => props.theme.color["Title"]};
    font-size: ${props => props.theme.text.f14};
    font-weight: ${props => props.theme.text.bold};
    margin-bottom: ${props => props.theme.emConverter(26)};
  }

  .stepGroup {
    display: grid;
    grid-column-gap: ${props => props.theme.emConverter(15)};
    grid-template-columns: 4% 93%;
    margin-bottom: ${props => props.theme.emConverter(20)};
  }

  .stepTitle {
    color: ${props => props.theme.color["Title"]};
    font-size: ${props => props.theme.text.f14};
    font-weight: ${props => props.theme.text.bold};
    margin-bottom: 1em;
  }

  .stepGroup {
    display: grid;
    grid-column-gap: ${props => props.theme.emConverter(15)};
    grid-template-columns: 4% 93%;
    margin-bottom: ${props => props.theme.emConverter(20)};
  }
  
  .sectionHeader {
    color: ${props => props.theme.color["Title"]};
    font-size: ${props => props.theme.text.f14};
    font-weight: ${props => props.theme.text.semibold};
    margin-bottom: ${props => props.theme.emConverter(14)};
  }

  .downloadButtonIcon {
    padding: 0;
    font-size: ${props => props.theme.text.f14};
  }

  .smoothScrollbarFillParent {
    & > section,
    & > section > .scroll-content,
    & > section > .scroll-content > div{
      height: 100%;

    }
  }

  .antSpinFillParent {
    & > .ant-spin-nested-loading,
    & > .ant-spin-nested-loading > .ant-spin-container {
      height: 100%;
    }
  }

  .ant-form-item-has {
    &-error {
      // taken from ant-d
      .quill,
      .quill:focus,
      .quill:hover {
        .ql-toolbar {
          border: 1px solid;
          border-color: #d9d9d9 !important;
          border-top-color: #ff4d4f !important;
          border-left-color: #ff4d4f !important;
          border-right-color: #ff4d4f !important;
          border-radius: 2px 2px 0px 0px;
          transition: border-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        .ql-container {
          border: 1px solid;
          border-color: #d9d9d9 !important;
          border-bottom-color: #ff4d4f !important;
          border-left-color: #ff4d4f !important;
          border-right-color: #ff4d4f !important;
          border-radius: 0px 0px 2px 2px;
          transition: border-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
      .quill:focus,
      .quill-focused {
        outline: 0;
        box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
        transition: border-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }

    &-success {
      .quill,
      .quill:focus,
      .quill:hover {
        .ql-toolbar {
          border: 1px solid;
          border-color: #d9d9d9 !important;
          transition: border-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        .ql-container {
          border: 1px solid;
          border-color: #d9d9d9 !important;
          transition: border-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }

    &-warning {
      .quill,
      .quill:focus,
      .quill:hover {
        .ql-toolbar {
          border: 1px solid;
          border-color: #d9d9d9 !important;
          border-top-color: #ffc53d !important;
          border-left-color: #ffc53d !important;
          border-right-color: #ffc53d !important;
          border-radius: 2px 2px 0px 0px;
          transition: border-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        .ql-container {
          border: 1px solid;
          border-color: #d9d9d9 !important;
          border-bottom-color: #ffc53d !important;
          border-left-color: #ffc53d !important;
          border-right-color: #ffc53d !important;
          border-radius: 0px 0px 2px 2px;
          transition: border-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
      .quill:focus,
      .quill-focused {
        outline: 0;
        box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
        transition: border-color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }

  .fefTable {
    .ant-table {
      .ant-table-thead {
        .ant-table-cell {
          border: solid thin lightgray;
          border-left: none;
          border-right: none;
          font-weight: ${props => props.theme.text.bold};
          padding-top:0.5em;
          padding-bottom:0.5em;
        }

        .ant-table-column-sorters {
          border-left: none;
          border-right: none;
          font-weight: ${props => props.theme.text.bold};
          padding-top:0.5em;
          padding-bottom:0.5em;
        }

        .ant-table-filter-column-title {
          border: none;
          border-left: none;
          border-right: none;
          font-weight: ${props => props.theme.text.bold};
          padding-top:0.5em;
          padding-bottom:0.5em;
        }
    }
  }
}


`;

export default GlobalTheme;
